import * as React from "react"
import { motion, AnimatePresence } from "framer-motion";

import Layout from "../components/layout"
import Warehouses from "../components/warehouses"
import { Link } from "gatsby"

import documentos from "../images/documentos.svg"
import paquetes from "../images/paquetes.svg"
import exportaSimple from "../images/exporta-simple.svg"
import courier from "../images/expo-documentos.png"
import estrella from "../images/estrella-bca.svg"
import cobertura from "../pdf/COBERTURA-GEOGRAFICA-Y-TIEMPOS-DE-TRANSITO-2022.pdf"

const ExportacionPage = () => {
  const [courierExpand, setCourierExpand] = React.useState(false)
  const [exportaExpand, setExportaExpand] = React.useState(false)
  const [cargaExpand, setCargaExpand ] = React.useState(false)

  return (
  <Layout
	seccion="exportacion"	
	title="Exportación"	
	linkTo="courier"
	>		
		<div id="courier" className="raicoContainer mt-30">
			<div className="row">
				<div id="expCol" className="col-sm-6">
					<div className={"expTxt bgBlue "+(courierExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
								
								<h2>Servicio Courier Internacional</h2></div>
								{/*
								<p className="text-white">Realice sus envios a todo el mundo rápido y seguro.</p>
								
								<p className="text-white"
								   style={{
									   paddingTop: '10px',
									   marginBottom: '40px'
								   }}>Ofrecemos el mejor tiempo de tránsito.</p>
								
								
								<p className="text-white">Servicio de Transporte aéreo internacional de documentos <br/>y paquetería, puerta a puerta, hacia todo el mundo <br/>hasta 50kg de peso bruto y hasta Usd 3000 de valor fob.</p>
								*/}
								
								<p className="text-white">Servicio de transporte aéreo internacional de documentos <br/>y paquetería, puerta a puerta, hacia todo el mundo.</p>
								{/* <br/>hasta 50kg de peso bruto y hasta Usd 3000 de valor Fob.</p>*/ }
								
								<p className="text-white requisitos">FUEL MENSUAL "18.58%"</p>

								<div id="expandCourier" className={"expand text-white "+(courierExpand ? 'invisible' : '')}
									onClick={() => setCourierExpand(!courierExpand)}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
	  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
	</svg>
									CONOCER MÁS
								</div>
							</div>
					</div>
				</div>
				
					<div className={"col-sm-6 expImg courier"} 
								  data-aos="zoom-in"								  
								  data-aos-duration="900">
						<div className={courierExpand ? 'expanded' : ''}></div>
					</div>
			</div>
			<AnimatePresence initial={false}>
				{courierExpand && (
				  <motion.section
					key="content"
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
					  open: { opacity: 1, height: "auto" },
					  collapsed: { opacity: 0, height: 0 }
					}}
					transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
				  >
						<div className="row">
							<div className="col-sm-6 serviceExpand pr-0">
								<div className="bgBlue d-flex flex-column">
									<div className="leftExpand">
										<div className="courierTitle d-flex align-items-start">
											<img src={paquetes} />
											<h2 className="text-white">
											Economy
											{/*Servicio Courier<br/> Internacional Documentos */}
											</h2>
										</div>
										{/* <p>Economy Documentos</p> */}
										<p>Servicio courier internacional puerta a puerta de documentos y paquetes al
exterior de hasta 50 kg. y USD 3.000 dólares de valor FOB sin prioridad de embarque y entrega.</p>

										<p>Servicio diseñado para bajar los costos de sus envíos de documentos y paquetes al exterior.
Con seguimiento online del status de sus envíos (Track &amp;Trace) y cobertura mundial.</p>
										
										<p>Si desea contratar o recibir más información sobre nuestro servicio:</p>
										<p className="requisitos"><a href="mailTo:expo@raiconet.com" target="_blank">CONSULTE AQUÍ</a></p>
										
{/* <p>Servicio diseñado para bajar sus costos a todo el<br/>
										mundo. Tiene seguimiento online y entrega puerta a
puerta.</p> 
										
										<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
										
										<p>Priority Documentos</p>
										<p>Servicio diseñado para envíos de documentos en tiempo
										récord. Tiene seguimiento online y entrega puerta a
										puerta.</p>
*/}
										
										<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
										
									</div>
									<img src={courier} className="imgExpandLeft d-none d-sm-block" 
									style={{marginTop: 'auto'}}/>
								</div>
							</div>
							<div className="col-sm-6 serviceExpand pl-0">
								<div className="bgBlue">
									<div className="courierTitle d-flex align-items-start">
										<img src={documentos} />
										<h2 className="text-white">
										Priority
										{/*Servicio Courier<br/> Internacional Paquetes */}
										</h2>
									</div>
									{/*
									<p>Economy Paquetes</p>
									<p>Servicio diseñado para bajar sus costos en muestras a<br/>
									todo el mundo. Tiene seguimiento online y entrega puerta<br/>
									a puerta, con un tiempo estimado de 4 a 6 días hábiles.</p>
									
									<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
									
									<p>Priority Paquetes</p>
									<p>Servicio diseñado para envíos de paquetes con entregas<br/>
									en tiempo récord a todo el mundo. Tiene seguimiento<br/>
									online, atención al cliente a disposición y entrega puerta a<br/>
									puerta, con un tránsito estimado de 2 a 4 días hábiles.</p>
									*/ }
									
									<p>Servicio courier internacional puerta a puerta de documentos y paquetes al exterior de hasta 50 kg. y USD 3.000 dólares de valor FOB. Envíos de carácter urgente, con prioridad de embarque y entrega.</p>
									
									<ul className="beneficios"
									style={{marginBottom: '20px'}}>
										<li><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /></li>
									</ul>
									<ul className="beneficios">
										<li><img src={estrella} alt="" title="" />Operamos con los mejores tiempos de tránsito.</li>
										<li><img src={estrella} alt="" title="" />Retiramos sus envíos desde cualquier punto del país hacia el resto del mundo.</li>
										<li><img src={estrella} alt="" title="" />Único Courier que ofrece packaging personalizado para cada necesidad.</li>
										<li><img src={estrella} alt="" title="" />Asignación de Ejecutivo de cuentas y Customer Service exclusivo para cada cliente.</li>
									{/*<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Somos los únicos que operamos en el país como<br/> correo de correos.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Retiramos envíos desde cualquier ciudad de Argentina<br/> hacia el resto del mundo.</li>
										<li>
										<svg style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Ejecutivo de Cuentas y Customer Service exclusivo<br/> para cada cliente.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>A través de nuestra web podrá: Realizar pedidos de<br/>
										recolección online, solicitar cotización, consultar status de<br/>
									pedidos (Track &Trace). Cobertura Mundial</li>	*/}									
									</ul>
									
									<p>Si desea contratar o recibir más información sobre nuestro servicio:</p>
										<p className="requisitos"><a href="mailTo:expo@raiconet.com" target="_blank">CONSULTE AQUÍ</a></p>
										
									<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
									
									
									{/*<p>Para solicitar una cotización enviar un mail a
									expo@raiconet.com con los siguientes datos:</p>

									<ul>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Origen.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Peso (Kg).</li>
										<li><svg 
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}}
										xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										<span className="d-inline-block">Medidas (cm) o Peso Volumétrico<br/>
										(Largo x alto x ancho/5000).</span>
										</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Valor FOB (USD).</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Descripción del contenido</li>
									</ul>
									*/ }
								</div>
							</div>
						</div>
				  </motion.section>
				)}
			</AnimatePresence>
		</div>
		<div id="expSimple" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-sm-6 pr-0">
					<div className={"expTxt bgBlue "+(exportaExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta"
								style={{marginBottom: '30px'}}
								>
									<h2>Servicio<br/> Exporta Simple</h2>
									<img id="exportaSimpleIcon" src={exportaSimple} alt="Exporta Simple" title="Exporta Simple" />
								</div>
								{/*
								
								<p className="text-white">Facilitamos su exportación.</p>
								
								<p className="text-white">Ideal para pymes, empresas regionales y pequeños emprendedores.<br/>
								Somos uno de los operadores nacionales en<br/>
formar parte de la Plataforma de Exporta Simple.</p> 
								
								<p className="text-white"
								style={{paddingRight: '0'}}
								>Es una herramienta diseñada por el Ministerio de Producción<br/> para facilitar las operaciones de exportación de las pymes, empresas regionales y pequeños emprendedores	
								{!exportaExpand ? '.'
								: <span> para <br/>exportar hasta USD 600.000 de valor FOB anual en envíos<br/> de hasta USD 15.000 y sin límite de peso. Pueden despacharse varios envíos juntos. <br/> No se requiere licencia de Exportador ni despachante y cuenta con reintegros a la exportación.</span>
								}
								</p>
*/}				
								{/*exportaExpand &&
								<p className="requisitos"><a href="https://exportasimple.raiconet.com/cotizar" target="_blank">CALCULA TU ENVÍO</a></p>
*/}

								<p className="text-white"
								style={{paddingRight: '0'}}
								>Servicio de transporte aéreo de exportación puerta a puerta<br/> o puerta aeropuerto que se perfecciona a través de la plataforma digital Exporta Simple.</p>
								
								{!exportaExpand && 
								<div className={"expand text-white "+(exportaExpand ? 'invisible' : '')}
									onClick={() => setExportaExpand(!exportaExpand)}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
	  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
	</svg>
									CONOCER MÁS
								</div>
								}
								
								{/*!exportaExpand && 
								<div className={"expand text-white "+(exportaExpand ? 'invisible' : '')}
									style={{ marginLeft: '5px' }}
									onClick={ () => window.open("https://exportasimple.raiconet.com/cotizar")}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
	  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
	</svg>
									CALCULA TU ENVÍO
								</div>
							*/}

								
								
				<AnimatePresence initial={false}>
				{exportaExpand && (
				  <motion.section
					key="content"
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
					  open: { opacity: 1, height: "auto" },
					  collapsed: { opacity: 0, height: 0 }
					}}
					transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
				  >
					<p>Es una herramienta diseñada por el Ministerio de Producción para facilitar las operaciones de exportación de las pymes, empresas regionales y pequeños emprendedores para exportar hasta USD 600.000 de valor FOB anual en envíos de hasta USD 15.000 y sin límite de peso. Pueden despacharse varios envíos juntos. <br/> No se requiere licencia de Exportador ni despachante y cuenta con reintegros a la exportación.</p>
								  
								  <p>Puerta-puerta:</p>

								  <p>La entrega se realizará a domicilio y el éxito de su envío
estará sujeto al tipo de mercadería y a la normativa
vigente de cada país.
			</p>								  
								</motion.section>
				)}
				</AnimatePresence>
							</div>
					</div>
				</div>
				
				<div id="" className={"col-sm-6 expImg expSimple"} 
							  data-aos="zoom-in"								  
							  data-aos-duration="900">
					<div className={exportaExpand ? 'expanded' : ''}></div>
				</div>
			</div>
			<AnimatePresence initial={false}>
				{exportaExpand && (
				  <motion.section
					key="content"
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
					  open: { opacity: 1, height: "auto" },
					  collapsed: { opacity: 0, height: 0 }
					}}
					transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
				  >
				<div className="row">
					<div className="col-sm-6 serviceExpand pr-0">
						<div className="bgBlue">
							<div className="leftExpand">								  
								  <p>Puerta-aeropuerto:</p>
								  
								  <p>Gestionamos la reserva con la aerolínea y una vez
arribada la carga al aeropuerto destino, es
responsabilidad del comprador la liberación y el pago
de aranceles.</p>
								  <p>Recuerde que cuenta con nuestro departamento de
atención al cliente para tomar la mejor decisión.</p>								 
								
									<ul>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Clave Única de Identificación Tributaria (CUIT) y
Clave Fiscal con Nivel de Seguridad 3 como mínimo.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>El monto anual de facturación de estas
exportaciones no podrá superar el valor FOB
equivalente a 600 mil dólares por sujeto.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Cada operación individual no podrá superar el valor
FOB de 15 mil dólares.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Los bienes a exportarse deberán ser producidos en
el país, y no alcanzados por prohibición, suspensión o
cupo a la exportación.</li>										
									</ul>
								</div>
							</div>
						</div>
						<div className="col-sm-6 serviceExpand pl-0">
							<div className="bgBlue">
								<ul id="expSimpleULcomp">
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
									El peso unitario de cada envío será ilimitado.</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Los bultos no deberán superar los 145 cm de ancho
	por 180 cm de alto y sin límite de largo.</li>										
								</ul>
								
								
								<p>Sugerimos consultar las restricciones y limitaciones de
	cada aerolínea según el destino.</p>
								<p>Para solicitar una cotización enviar un mail a
	exposimple@raiconet.com con los siguientes datos:</p>

								<ul>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
									Destino.</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Peso (Kg).</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
									Medida (cm): largo x alto x ancho</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Posición arancelaria</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Cantidad de bultos</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Valor FOB (USD).</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Descripción del contenido / tipo de mercadería</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Seguro de mercadería (sí o no)</li>
									<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Plazos de entrega</li>
								</ul>
								
								<p>Si desea contratar o recibir más información sobre nuestro servicio:</p>

								<p className="requisitos"><a href="mailTo:exposimple@raiconet.com" target="_blank">CONSULTE AQUÍ</a></p>
									
								<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
								
									
							</div>
						</div>
					</div>
				  </motion.section>
				)}
			</AnimatePresence>
		</div>
		<div id="carga" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-sm-6 pr-0">
					<div className={"expTxt bgBlue "+(cargaExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
								
								<h2>Servicio de Carga Aérea</h2></div>
								{/*
								<p className="text-white">Cobertura mundial para sus cargas internacionales de modo seguro y confiable. </p>
								
								<p className="text-white">Nos encargamos de hacer llegar su mercadería de la <br/>forma más eficaz y económica.</p>
								
								<p className="text-white">Servicio de CARGA AÉREA Internacional con la flexibilidad necesaria para adaptar la entrega según la necesidad de <br/>cada cliente, podrà elegir entre una entrega:<br/> PUERTA-PUERTA o PUERTA-AEROPUERTO.</p>
								*/ }
								
								<p
								style={{paddingRight: '0'}}
								className="text-white">Servicio de carga aérea de exportación puerta a puerta <br/>o puerta aeropuerto.</p>
								
								{/*!cargaExpand && */}
									<div id="expandCarga" className={"expand text-white "+(cargaExpand ? 'invisible' : '')}
										onClick={() => setCargaExpand(!cargaExpand)}
									>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
		  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
		</svg>
										CONOCER MÁS
									</div>
								{/**/}
								<AnimatePresence initial={false}>
								{/* && cargaExpand && (
								  <motion.section
									key="content"
									initial="collapsed"
									animate="open"
									exit="collapsed"
									variants={{
									  open: { opacity: 1, height: "auto" },
									  collapsed: { opacity: 0, height: 0 }
									}}
									transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
								  >									  
									
									<ul>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Reservamos con anticipación espacios de bodegas con las principales compañías aéreas.</li>										
									</ul>
								  </motion.section>
								)*/}
								</AnimatePresence>
							</div>
					</div>
				</div>
				
				<div id="" className={"col-sm-6 expImg carga"} 
							  data-aos="zoom-in"								  
							  data-aos-duration="900">
					<div className={cargaExpand ? 'expanded' : ''}></div>
				</div>
			</div>
			<AnimatePresence initial={false}>
				{cargaExpand && (
				  <motion.section
					key="content"
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
					  open: { opacity: 1, height: "auto" },
					  collapsed: { opacity: 0, height: 0 }
					}}
					transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
				  >
					<div className="row">
						<div className="col-sm-6 serviceExpand pr-0">
							<div className="bgBlue"
							style={{paddingBottom: '30px' }}
							>
								<div className="leftExpand">								  								
									 
									<p>Puerta - puerta:</p>
									  
									  <p>Retiramos la carga del domicilio de nuestros clientes, y coordinamos el pago de aranceles de liberación y entrega en destino. </p>
									  
									<p>Puerta - aeropuerto:</p>
									  
									<p>Realizamos la documentación aduanera, nos encargamos
									de emitir su Permiso de Embarque y despachamos la
									mercadería a través de aerolíneas de alto nivel hasta el
									aeropuerto internacional más cercano al destinatario.</p>
								</div>
							</div>
						</div>
						<div className="col-sm-6 serviceExpand pl-0">
							<div className="bgBlue">
								
								<ul className="beneficios"
								style={{marginTop: '0px' }} >
									<li><img src={estrella} alt="" title="" />
										Reservamos con anticipación espacios de bodegas con las principales compañías aéreas.</li>
									<li><img src={estrella} alt="" title="" />
									Brindamos información constante del status de su carga hasta concretar su entrega.</li>
									<li
									
									><img src={estrella} alt="" title="" />
									Acompañamos a nuestros clientes en la emisión de la documentación requerida para cumplir con una exportación exitosa.</li>
								</ul>
								
								
								<p 
								style={{ marginTop: '30px' }}
								className="requisitos">¡RECUERDE TENER LA LICENCIA DE EXPORTADOR HABILITADA!</p>
								
								<p>Para solicitar una cotización enviar un mail a
									expo@raiconet.com con los siguientes datos:</p>

									<ul
									style={{ marginBottom: '40px' }}
									>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Destino.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Peso (Kg).</li>
										<li><svg 
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}}
										xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										<span className="d-sm-inline-block">Medidas (cm) o Peso Volumétrico <br className="d-none d-sm-block" />
										(Largo x alto x ancho/5000).</span>
										</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Valor FOB (USD).</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Descripción del contenido</li>
									</ul>
									
									<p>Si desea contratar o recibir más información sobre nuestro servicio:</p>
										<p className="requisitos"><a href="mailTo:expo@raiconet.com" target="_blank">CONSULTE AQUÍ</a></p>
										
									<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>
																		
							</div>
						</div>
					</div>
				  </motion.section>
				)}
			</AnimatePresence>
		</div>
		<Warehouses />
  </Layout>
)
}
export default ExportacionPage
